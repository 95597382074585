import { FunctionComponent, useEffect } from 'react';
import { getConfig } from '@belong/configs/next/config';
import useAnalyticsEvents from '@belong/providers/analyticsEvents';
import useConnectToAnalyticsVendors from '@belong/react-hooks/useConnectToAnalyticsVendors';
import { pageView } from '@belong/analytics';

const nextConfig = getConfig().publicRuntimeConfig;

const AnalyticsDispatcher: FunctionComponent<{ pageData: any; isAuthenticatedPage?: boolean }> = ({
  pageData,
  isAuthenticatedPage
}) => {
  const analyticsEvents = useAnalyticsEvents();
  useConnectToAnalyticsVendors(analyticsEvents, nextConfig.analytic.shouldRun);

  useEffect(() => {
    if (!nextConfig.analytic.shouldRun) {
      return;
    }

    const pageViewEvent = pageView(pageData, nextConfig, isAuthenticatedPage);
    analyticsEvents.send(pageViewEvent);
  }, [analyticsEvents, pageData, isAuthenticatedPage]);

  return null;
};

export default AnalyticsDispatcher;

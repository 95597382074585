import { Cookies, CookiesProvider } from 'react-cookie';
import { ThemeProvider } from 'styled-components';

import { Provider as AnalyticsEvents } from '@belong/providers/analyticsEvents';
import { Provider as Authentication } from '@belong/providers/authentication';
import { Provider as DeviceCapabilities } from '@belong/providers/deviceCapabilities';
import { Provider as Runtime } from '@belong/providers/runtime';
import { Provider as User } from '@belong/providers/user';
import type { ITheme } from '@belong/types';
import { PlatformConfigProvider } from '@belong/utils/device/device-bridge/usePlatformConfig';
import { SearchProvider } from '@belong/search/src/SearchProvider';
import { ISearchServer } from '@belong/search';

interface IProviderProps extends ISearchServer {
  theme: ITheme;
  cookies: Cookies;
  children: React.ReactNode;
}

export const Providers: React.FC<IProviderProps> = ({ theme, cookies, children, searchServerUrl }) => {
  return (
    <PlatformConfigProvider cookies={cookies}>
      <CookiesProvider cookies={cookies}>
        <ThemeProvider theme={theme}>
          <Authentication>
            <DeviceCapabilities>
              <User>
                <Runtime>
                  <SearchProvider searchServerUrl={searchServerUrl}>
                    <AnalyticsEvents>{children}</AnalyticsEvents>
                  </SearchProvider>
                </Runtime>
              </User>
            </DeviceCapabilities>
          </Authentication>
        </ThemeProvider>
      </CookiesProvider>
    </PlatformConfigProvider>
  );
};

export default Providers;

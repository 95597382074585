import { getConfig } from '@belong/configs/next/config';

import { logger } from '@belong/logging/logger';
import { getRoutePattern, isBlockedPage, matchRoute, withBasePath } from '@belong/utils';

export const { basePath, env } = getConfig().publicRuntimeConfig;

export const STATIC_ASSETS = withBasePath('/static');

export const ROUTES: Record<string, string> = {
  HOME: withBasePath('/'),
  FOUR_OH_FOUR: withBasePath('/404'),
  MOBILE: withBasePath('/mobile'),
  INTERNET: withBasePath('/internet'),
  MOBILE_WILDCARD: withBasePath('/mobile/*'),
  INTERNET_WILDCARD: withBasePath('/internet/*')
};

export const AUTHENTICATED_ROUTES: Record<string, string> = {
  TROUBLESHOOTING: '/nbn/:serviceId/troubleshooting'
};

export const EXTERNAL_ROUTES = {
  MY_SERVICES: '/new/services',
  LOGIN: '/login'
};

/* istanbul ignore next */
export const debugNavigation = (pageProps, router): void => {
  /* eslint-disable no-console */
  console.group('Navigation');
  logger.debug('Path:', router?.pathname);
  logger.debug('Request:', router?.asPath);
  if (env.isDevelopment) {
    console.log('PageProps:', pageProps);
  }
  console.groupEnd();
  /* eslint-enable no-console */
};

/*
 * When a route is added to this, enable the test to ensure the actually works
 * Test name: "should return true if the dynamic route feature is disabled"
 */
export const isRouteBlocked = (pageUrl: string): boolean => {
  // Block pages based on feature toggle values
  const blockedPages = {};

  const blockedDynamicPages = {};
  const isDynamicRouteBlocked = Object.entries(blockedDynamicPages).some(
    ([route, featureAvailable]) => matchRoute(pageUrl, [getRoutePattern(route)]) && featureAvailable
  );

  return isDynamicRouteBlocked || isBlockedPage(pageUrl, blockedPages);
};

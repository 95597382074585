import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { COOKIES } from '@belong/constants';
import { validateAuthCookie, validateMfaCookie } from '@belong/cookies';
import { usePlatformConfig } from '@belong/utils/device/device-bridge/usePlatformConfig';
import { USER_TYPE } from '@belong/types';
import { logger } from '@belong/logging/logger';
import { clearSession, logout } from '@belong/user';

import createProvider from '../_factory';

export interface IAuthentication {
  isLoggedIn: boolean;
  userType: USER_TYPE;
}

export const checkAuthenticationCookies = (cookies: { [name: string]: any }, isNativeApp: boolean): IAuthentication => {
  let userType = USER_TYPE.CUSTOMER;
  let authCookie = cookies[COOKIES.BELONG];
  let mfaCookie = cookies[COOKIES.MFA_ENROL];

  try {
    authCookie = JSON.parse(decodeURIComponent(cookies[COOKIES.BELONG]));
    mfaCookie = JSON.parse(decodeURIComponent(cookies[COOKIES.MFA_ENROL]));
  } catch (error) {
    // do nothing
  }

  // no cookie - not logged in
  if (!authCookie) {
    return {
      isLoggedIn: false,
      userType
    };
  }

  // make sure cookies are valid
  if (!validateAuthCookie(authCookie) || (!isNativeApp && mfaCookie && !validateMfaCookie(mfaCookie))) {
    logger.error('Invalid cookie found - clearing session');
    clearSession();
    return {
      isLoggedIn: false,
      userType
    };
  }

  if (authCookie.userType) {
    userType = Object.values(USER_TYPE).includes(authCookie.userType) ? authCookie.userType : USER_TYPE.CUSTOMER;
  }

  if (userType === USER_TYPE.AGENT) {
    return {
      isLoggedIn: false,
      userType
    };
  }

  return {
    isLoggedIn: true,
    userType
  };
};

export interface IProvider extends IAuthentication {
  logOut: (redirectTo: string) => void;
}

type hook = (/* this hook takes no args */) => IProvider;

/**
 * useAuthentication
 *
 * @dependency CookiesProvider from 'react-cookie'
 * */
const useAuthentication: hook = () => {
  const { isNativeApp } = usePlatformConfig();
  const [cookies] = useCookies([COOKIES.BELONG, COOKIES.MFA_ENROL]);

  const [userAuthentication, setUserLoggedIn] = useState<IAuthentication>(
    (): IAuthentication => checkAuthenticationCookies(cookies, isNativeApp)
  );

  useEffect(() => {
    setUserLoggedIn(checkAuthenticationCookies(cookies, isNativeApp));
  }, [cookies[COOKIES.BELONG], cookies[COOKIES.MFA_ENROL], isNativeApp]);

  const logOut = (redirectTo: string): void => {
    setUserLoggedIn({ isLoggedIn: false, userType: USER_TYPE.CUSTOMER });
    logout(redirectTo);
  };

  return {
    ...userAuthentication,
    logOut
  };
};

const { hook, provider } = createProvider<hook>(useAuthentication, 'useAuthentication');

export const Provider = provider;

export default hook;

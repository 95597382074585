import React, { FunctionComponent } from 'react';

import { GlobalFooter } from '@belong/ui-components';
import { IFooterMenu } from '@belong/types';

const GlobalFooterContainer: FunctionComponent<IFooterMenu> = props => {
  return <GlobalFooter {...props} />;
};

export default GlobalFooterContainer;
